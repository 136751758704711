export const ROUTES = {
  DEFAULT: '/',
  LOGIN: '/login',
  NOTFOUND: '/404',
  HOME: '/preshows',
  PRESHOW: '/preshows/:id',
  TEAM: '/team',
  SETTINGS: '/settings'
};

export const ErrorMessage = {
  EMAIL_REQUIRED: 'Email is required',
  EMAIL_INVALID: 'Invalid email address',
  PASSWORD_REQUIRED: 'Password is required',
  CURRENT_PASSWORD_REQUIRED: 'Current password is required',
  NEW_PASSWORD_REQUIRED: 'New password is required',
  CONFIRM_PASSWORD_REQUIRED: 'Confirm new password is required',
  PASSWORD_NOT_MATCH: 'Confirm password does not match with the new password'
};

export const NAVBAR_ROUTES = [
  {
    title: 'Home',
    icon: '/static/images/home/home-default.png',
    iconActive: '/static/images/home/home-selected.png',
    href: ROUTES.HOME
  },
  {
    title: 'Team',
    icon: '/static/images/home/team-default.png',
    iconActive: '/static/images/home/team-default.png',
    href: ROUTES.TEAM
  },
  {
    title: 'Setting',
    icon: '/static/images/home/settings-default.png',
    iconActive: '/static/images/home/settings-default.png',
    href: ROUTES.SETTINGS
  }
];

export const PERSIST_KEY = 'preshow-brands';

export const TABS = {
  SPOT_ANALYTICS: ['Analytics', 'Survey Results', 'Attention Graph']
};

export const PERIOD_DAYS = [
  {
    label: '24 hours',
    value: 1
  },
  {
    label: '1 week',
    value: 7
  },
  {
    label: '1 month',
    value: 30
  },
  {
    label: 'Last 90 days',
    value: 90
  }
];

export const GENDER = [
  {
    label: 'Gender',
    value: 'all'
  },
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  }
];

export const MOMENT_FORMAT = {
  DEFAULT: 'YYYY/MM/DD',
  PRIMARY_DATE: 'YYYYMMDD',
  SECONDARY_DATE: 'MMM D',
  PRIMARY_TIME: 'H:mm:ss',
  SECONDARY_TIME: 'mm:ss'
};

export const BAR_COLORS = ['#d5a15a', '#028bd2', '#7EC4E8', '#DAF2FF'];
export const VIDEO_PLAYER_SPEED = [0.5, 1, 1.5, 2];
export const PAGINATION_LIMIT = 10;
